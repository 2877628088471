<template>
  <div class="Register">
    <div class="avater">
      <van-image
        style="box-shadow: 0 8px 12px #ebedf0"
        round
        width="6rem"
        height="6rem"
        :src="loc.headimgurl"
      />
    </div>
    <div class="box">
      <van-field
        v-model="form.phone"
        type="number"
        name="phone"
        label="手机号码"
        autosize
        placeholder="请输入您的手机号码"
        :left-icon="icon.picon"
        :rules="[{ required: true, message: '请填写手机号码' }]"
      />
      <van-field
        v-model="form.code"
        type="number"
        name="code"
        label="验证码"
        autosize
        :left-icon="icon.cicon"
        placeholder="验证码"
        :rules="[{ required: true, message: '请填写验证码' }]"
      >
        <template #button>
          <van-button
            v-if="isCode"
            @click="onSendCode"
            color="#ff976a"
            size="small"
            >发送验证码</van-button
          >
          <span v-else style="color: #ff976a">{{ countdown }}s</span>
        </template>
      </van-field>
    </div>
    <div class="rdis">
      <van-checkbox icon-size="18" v-model="form.checked">
        我已阅读并同意
        <span style="color: #3694f3"> 《中广金融公众号用户注册协议》 </span>
      </van-checkbox>
    </div>
    <div class="btns">
      <van-button
        @click="onSubmit"
        style="width: 100%; border-radius: 5px"
        type="primary"
      >
        注 册
      </van-button>
    </div>
  </div>
</template> 

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Notify } from "vant";
import { accesstoken_api, wxuserinf_api, register_api } from "../store/apis";
export default {
  setup() {
    const loc = ref({
      openid: "",
      nickname: "",
      sex: 0,
      province: "",
      city: "",
      country: "",
      headimgurl: "",
      privilege: "",
      unionid: "",
    });

    const form = ref({
      phone: "",
      code: "36621",
      checked: true,
    });
    const countdown = ref(10);
    const isCode = ref(true);
    const router = useRouter();
    const route = useRoute();
    if (route.query.code) {
      accesstoken_api(route.query).then((res) => {
        if (!res.errcode) {
          wxuserinf_api({
            access_token: res.access_token,
            openid: res.openid,
          }).then((resp) => {
            if (!resp.errcode) {
              loc.value = resp;
            }
          });
        }
      });
    }
    const onSendCode = () => {
      isCode.value = false;
      let timer = setInterval(() => {
        if (countdown.value <= 0) {
          clearInterval(timer);
          isCode.value = true;
        }
        countdown.value -= 1;
      }, 1000);
    };
    const onSubmit = async () => {
      let resp = await register_api({
        username: loc.value.nickname,
        avatar: loc.value.headimgurl,
        sex: loc.value.sex,
        phone: form.value.phone,
        openid: loc.value.openid,
        code: form.value.code,
      });
      if (resp.code === 0) {
        Notify({ type: "success", message: "恭喜您,注册成功!" });
        window.localStorage.setItem("openid", loc.value.openid);
        router.push("/");
      } else {
        Notify({ type: "danger", message: "注册信息有误!" });
      }
    };

    return {
      loc,
      icon: {
        cicon: require("../assets/code.png"),
        picon: require("../assets/phone.png"),
      },
      form,
      countdown,
      isCode,
      onSendCode,
      onSubmit,
    };
  },
};
</script>


<style lang="scss">
.Register {
  height: 100vh;
  background-color: #f7f8fa;
  padding: 10px;
  font-size: 12px;

  .avater {
    text-align: center;
    margin-top: 20px;
  }
  .box {
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 8px 12px #ebedf0;
  }
  .rdis {
    margin-top: 40px;
    padding: 2px;
  }
  .btns {
    margin-top: 15px;
  }
}
.van-field__left-icon {
  line-height: 1.8;
}
</style>
